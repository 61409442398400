/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  createMuiTheme,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { CustomBackdrop } from "../../../../../common/BackDrop/BackDrop";
import { CustomButton } from "../../../../../common/CustomButton/CustomButton";
import { CustomSnackBar } from "../../../../../common/CustomSnackBar/CustomSnackBar";
import { TOKEN } from "../../../../../constant";
import {
  ACCUEIL_URL,
  COMMISSIONING_URL,
  LOGIN_URL,
  SIGNUP_URL_ID,
} from "../../../../../constant/url";
import { legalEntityTypes } from "../../../../../graphql/LegalEntityTypes/__generated__/legalEntityTypes";
import { LEGAL_ENTITY_TYPES } from "../../../../../graphql/LegalEntityTypes/query";
import {
  signUp,
  signUpVariables,
} from "../../../../../graphql/SignUp/__generated__/signUp";
import { DO_SIGNUP } from "../../../../../graphql/SignUp/mutation";
import { useStyles } from "../../style";
import { AuthContext } from "../../../../../provider/Authprovider";
import { getUser } from "../../../../../provider/localesStorage";

interface ILegalEntityPayload {
  legalEntityTypeId?: string | null;
  denomination?: string | null;
}
interface IEntity {
  id: string;
  label: string;
}
export interface IsignUpInput {
  lastName?: string | null;
  firstName?: string | null;
  isLegalEntity?: boolean | null;
  legalEntity?: ILegalEntityPayload | null;
  id: string;
  password: string;
}

const CardSignUpPM: React.FC = () => {
  const { setToken, setUserInfos } = useContext(AuthContext);
  const user = getUser();
  const style = useStyles();
  const history = useHistory();
  const [idError, setIdError] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [active, setActive] = useState<boolean>(false);
  const [input, setInput] = useState<IsignUpInput>({
    lastName: "",
    firstName: "",
    id: "",
    password: "",
    isLegalEntity: null,
    legalEntity: {
      denomination: "",
      legalEntityTypeId: "",
    },
  });

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#B48A4E",
      },
    },
  });

  const { data } = useQuery<legalEntityTypes>(LEGAL_ENTITY_TYPES);

  const legalEntityTypesMap: IEntity[] = useMemo(
    () =>
      (data &&
        data.legalEntityTypes &&
        data.legalEntityTypes.map((el) => ({
          id: el?.id || "",
          label: el?.label || "",
        }))) ||
      [],
    [data]
  );

  const [doSignUp, { loading: doSignUpLoading }] = useMutation<
    signUp,
    signUpVariables
  >(DO_SIGNUP, {
    onCompleted: (data) => {
      if (data.signUp.data) {
        history.push(SIGNUP_URL_ID, {
          ...data.signUp.data,
        });
      } else {
        setType("error");
        setOpen(true);
        setMessage(data.signUp.message || "Une erreur est survenue");
      }
    },
    onError: (errors) => {
      setOpen(true);
      setType("error");
      if (input.firstName === "" || input.lastName === "") {
        setMessage("Veuillez compléter tous les champs!");
      } else {
        errors?.graphQLErrors?.map((error) => {
          setMessage(error.message);
          return error;
        });
      }
    },
  });

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as
      | keyof IsignUpInput
      | "legalEntityTypeId"
      | "denomination";
    let value = event.target.value as string;

    if (name === "denomination" || name === "id") {
      value = value.trim();
    }

    if (name === "legalEntityTypeId") {
      setInput((prevstate) => ({
        ...prevstate,
        legalEntity: { ...prevstate.legalEntity, legalEntityTypeId: value },
      }));
    } else if (name === "denomination") {
      setInput((prevstate) => ({
        ...prevstate,
        legalEntity: { ...prevstate.legalEntity, denomination: value },
      }));
    } else {
      setInput((prevstate) => ({ ...prevstate, [name]: value }));
    }
  };

  const handleSubmit = () => {
    const { id, password, legalEntity } = input;

    if (
      !id ||
      !password ||
      !legalEntity ||
      !legalEntity.legalEntityTypeId ||
      !legalEntity.denomination
    ) {
      setOpen(true);
      setType("error");
      setMessage("Tous les champs sont requis");
      return;
    }

    if (id.length < 6 || id.length > 20) {
      setOpen(true);
      setType("error");
      setMessage(
        "L’identifiant de connexion choisi est incorrect : 6 à 20 caractères sont obligatoires. Recommencez s’il vous plaît."
      );
      return;
    }

    if (isNaN(+password) || password.length !== 6) {
      setOpen(true);
      setType("error");
      setMessage(
        "Le mot de passe choisi est incorrect : 6 chiffres sont obligatoires. Recommencez s’il vous plaît."
      );
      return;
    }

    doSignUp({
      variables: {
        signUpInput: {
          id: id.trim(),
          password: password.trim(),
          legalEntity,
          isLegalEntity: true,
        },
      },
    });
  };

  const onKeyPressed = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  useEffect(() => {
    if (
      !/^[a-zA-Z\u00C0-\u00FF0-9 ]*$/.test(
        `${input.legalEntity?.denomination}`
      ) &&
      input.legalEntity?.denomination !== ""
    ) {
      setActive(false);
      if (!open) {
        setOpen(true);
        setType("error");
        setMessage(
          "Les caractères spéciaux ne sont pas autorisés. L'accent ^ et le ¨ sont autorisés."
        );
      }
    } else {
      if (open) {
        setOpen(false);
        setMessage("");
      }
      if (
        input.id !== "" &&
        input.password !== "" &&
        input.legalEntity?.denomination !== "" &&
        input.legalEntity?.legalEntityTypeId !== ""
      ) {
        setActive(true);
      } else {
        setActive(false);
      }
    }
  }, [input]);

  useEffect(() => {
    const token = localStorage.getItem(TOKEN);
    if (!!token && user) {
      setUserInfos({
        isAdmin: user.isAdmin,
        userName: user.userName,
      });
      if (user.isAdmin) {
        history.push(COMMISSIONING_URL);
      } else {
        history.push(ACCUEIL_URL);
      }
    }
  }, []);

  return (
    <>
      <TextField
        className={style.textField}
        type="text"
        name="denomination"
        label="Dénomination ou Nom Commercial"
        variant="outlined"
        value={input.legalEntity?.denomination || ""}
        onChange={handleChange}
        onKeyDown={onKeyPressed}
      />
      <Typography className={style.textselect}>
        Sélectionnez le type de personne morale :
      </Typography>
      <Box className={style.formControle}>
        <FormControl
          fullWidth={true}
          variant="outlined"
          className={style.textField}
        >
          <InputLabel id="type">Type de personne morale</InputLabel>
          <Select
            labelId="type"
            id="example-select"
            value={input.legalEntity?.legalEntityTypeId || ""}
            onChange={handleChange}
            label="Type de personne morale"
            name="legalEntityTypeId"
            displayEmpty={true}
          >
            {legalEntityTypesMap.map((entity: IEntity) => (
              <MenuItem key={entity.id!} value={entity.id!}>
                {entity.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          className={style.textField}
          type="text"
          name="id"
          label="Votre identifiant de connexion"
          variant="outlined"
          value={input.id}
          inputProps={{ maxLength: 20 }}
          onChange={handleChange}
          onKeyDown={onKeyPressed}
          onFocus={() => setIdError(true)}
          onBlur={() => setIdError(false)}
          error={idError && (input.id.length < 6 || input.id.length > 20)}
          helperText={
            idError && (input.id.length < 6 || input.id.length > 20)
              ? "6 à 20 caractères au choix (facile à mémoriser)."
              : ""
          }
        />
        <TextField
          className={style.textField}
          type="password"
          name="password"
          label="Votre mot de passe"
          variant="outlined"
          value={input.password}
          onChange={handleChange}
          onKeyDown={onKeyPressed}
          onFocus={() => setPasswordError(true)}
          onBlur={() => setPasswordError(false)}
          error={
            passwordError &&
            (isNaN(+input.password) || input.password.length !== 6)
          }
          helperText={
            passwordError &&
            (isNaN(+input.password) || input.password.length !== 6)
              ? "6 chiffres au choix (facile à mémoriser par vous)"
              : ""
          }
        />
      </Box>

      <ThemeProvider theme={theme}>
        <CustomButton
          disabled={!active}
          color="primary"
          nameBtn="VALIDER"
          variant="contained"
          onClick={handleSubmit}
          className={style.btnconnect}
        />
      </ThemeProvider>
      <CustomButton
        color="primary"
        nameBtn="ABANDONNER"
        variant="outlined"
        className={style.btnconnect}
        onClick={() => history.push(LOGIN_URL)}
        style={{
          marginTop: 30,
          marginBottom: 20,
          backgroundColor: "#FFEBA2",
          borderColor: "#FFEBA2",
          color: "#B48A4E",
        }}
      />
      <CustomSnackBar
        open={open}
        setOpen={setOpen}
        message={message}
        type={type}
      />
      <CustomBackdrop open={doSignUpLoading} />
    </>
  );
};

export default CardSignUpPM;
